import { FaInstagram, FaYoutube } from "react-icons/fa"; // Import icons
import Extracurricular from "./ExtraCurricular";

export default function AboutMe() {
  const hobbies = [
    {
      title: "Traveling",
      description:
        "Exploring new places and cultures is my passion. I love experiencing the beauty of the world, one destination at a time.",
      image: "./assets/about/travellingport.webp", // Replace with your image URL
      icons: [
        { icon: <FaInstagram />, link: "https://instagram.com/our_voyage_" },
        { icon: <FaYoutube />, link: "https://youtube.com/yourchannel" },
      ],
    },
    {
      title: "Workouts",
      description:
        "Fitness is a core part of my routine. Whether it's Bodyweight Exercises or Cardio, I enjoy pushing my limits.",
      image: "./assets/about/posing.webp", // Replace with your image URL
      icons: [{ icon: <FaInstagram />, link: "https://instagram.com/thejoey.in" }],
    },
    {
      title: "Vintage Motorcycles",
      description:
        "Restoring and maintaining vintage motorcycles is a unique way for me to combine my love for history and engineering.",
      image: "./assets/about/yezdisolo.webp", // Replace with your image URL
      icons: [{ icon: <FaInstagram />, link: "https://instagram.com/thejoey.moto" }],
    },
    {
      title: "Trekking",
      description:
        "I love climbing heights to find out what is it like from there",
      image: "./assets/about/moutwithdog.webp", // Replace with your image URL
      icons: [{ icon: <FaInstagram />, link: "https://instagram.com/thejoey.voyage" }
      ],
    },
    {
      title: "Bible Study",
      description:
        "Exploring the Scriptures to experience the word in my life.",
      image: "./assets/about/bibleport.webp", // Replace with your image URL
      icons: [{ icon: <FaInstagram />, link: "https://instagram.com/thejoey.in" }],
    },
    {
      title: "Playing Guitar",
      description:
        "Music is my creative outlet. Playing the guitar helps me unwind and express myself in ways words can't.",
      image: "./assets/about/guitar.webp", // Replace with your image URL
      icons: [{ icon: <FaInstagram />, link: "https://instagram.com/thejoey.in" }],
    }
  ];

  return (
    <div className="min-h-screen bg-[#1d1b16] text-[#e2d2ae] font-roboto">
      {/* Page Header */}
      <div className="py-12 bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] text-center shadow-lg">
        <h1 className="text-4xl sm:text-5xl font-kugile text-[#e2d2ae]">
          About Me
        </h1>
        <p className="mt-4 text-lg sm:text-xl text-[#c4b69c] font-light max-w-3xl mx-auto">
          A glimpse into my world beyond work—my hobbies and passions that fuel
          my creativity and joy.
        </p>
      </div>

      {/* Hobbies Section */}
      <div className="container mx-auto px-6 sm:px-12 lg:px-24 py-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        {hobbies.map((hobby, index) => (
          <div
            key={index}
            className="bg-gradient-to-b from-[#3e2c1b] to-[#2a241f] rounded-xl shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl"
          >
            {/* Hobby Image */}
            <div className="relative overflow-hidden">
              <img
              loading="lazy"
                src={hobby.image}
                alt={hobby.title}
                className="w-full h-64 object-cover transition-transform duration-300 lazy"
              />
              <div className="absolute inset-0 bg-black opacity-30 transition-opacity duration-300 hover:opacity-20"></div>
            </div>

            {/* Hobby Description */}
            <div className="p-6">
              <h2 className="text-2xl font-kugile mb-4 text-[#e2d2ae]">
                {hobby.title}
              </h2>
              <p className="text-lg text-[#c4b69c] leading-relaxed mb-4">
                {hobby.description}
              </p>
              {/* Icons */}
              <div className="flex gap-4">
                {hobby.icons.map((item, idx) => (
                  <a
                    key={idx}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#c4b69c] text-2xl hover:text-[#e2d2ae] transition"
                  >
                    {item.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div><Extracurricular/></div>
    </div>
  );
}
